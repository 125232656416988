<template lang="pug">
  include ../mixins
  .de-card.de-card--color_white.certificate-card(
    :class="{'de-card--space_top-sm': object.objStock}"
  )
    +b.certificate-detail
      +b.certificate-page-title--mobile
        +e.title
          +e.H1.title-content.de-text.--color_main.--weight_medium.--height_xs {{ _("Електронний подарунковий сертифікат") }}
      +b.certificate-preview
        //- +inc './parts/product-slider.jinja'
        +b.product-slider-wrapper
          +b.position--absolute
            +b.common-card.--main
              +b.common-card__label-wrapper
                +e.label--accent-1(
                  v-if='object.pricePromotion > 0'
                )
                  +e.SPAN.label-text {{ _("акция") }}
                  +e.label-icon
                    +b.I.de-icon.--type_percent
                +e.label--accent-2(
                  v-if='object.isNew'
                )
                  +e.SPAN.label-text {{ _("новинки") }}
                  +e.label-icon
                    +b.I.de-icon.--type_new
                +e.label--accent-3(
                  v-if='object.isHit'
                )
                  +e.SPAN.label-text {{ _("хит продаж") }}
                  +e.label-icon
                    +b.I.de-icon.--type_hot
                +e.label--cyan(
                  v-if='object.isRecommended'
                )
                  +e.SPAN.label-text {{ _("рекомендовано") }}
                  +e.label-icon
                    +b.I.de-icon.--type_star
                +e.label--coral(
                  v-if='object.isDiscounted'
                )
                  +e.SPAN.label-text {{ _("знижка") }}
                  +e.label-icon
                    +b.I.de-icon.--type_percent
                +e.label--orange(
                  v-if='object.isAwait'
                )
                  +e.SPAN.label-text {{ _("очікується") }}
                  +e.label-icon
                    +b.I.de-icon.--type_time
          link(
            v-if='formdata.template'
            rel="preload"
            as="image"
            :href="formdata.template.image"
            :imagesrcset="formdata.template.image"
          )
          ui-lightbox.js-lightbox(
            :source='[formdata.template.image,]'
            :is-slider="true"
          )
            template(
              #default="{ showImg, add }"
            )
              ui-slider.product-slider(
                @init='add'
                :options={
                  slidesToScroll: 1,
                  infinite: true,
                  slidesToShow: 1,
                  arrows: false,
                  dots: true,
                  speed: 500,
                  mobileFirst: true,
                  appendDots: '.product-slider-dots',
                  prevArrow: '<div class="slider-arrow slider-arrow--absolute-prev"\
                              aria-label="Previous"></div>',
                  nextArrow: '<div class="slider-arrow slider-arrow--absolute-next"\
                              aria-label="Next"></div>',
                  responsive: [
                    {
                      breakpoint: 564,
                      settings: {
                        arrows: true,
                      }
                    },
                    {
                      breakpoint: 992,
                      settings: {
                        arrows: false,
                      }
                    },
                    {
                      breakpoint: 1200,
                      settings: {
                        arrows: true,
                      }
                    },
                  ]
                }
              )
                template
                  //- +for("image in qs_images")
                  +b.product-slide.js-lightbox.pointer(
                    data-img=0
                  )
                    +e.wrapper
                      picture
                        source(
                          type="image/webp"
                          :srcset="formdata.template.image"
                        )
                        +e.image.IMG(
                          :alt="formdata.template.imageAlt"
                          :srcset="formdata.template.image"
                          :src="formdata.template.image"
                        )
              +b.product-slider-dots.ma--top_2xs
        +b.g-row--align_end.--appearance_spaced.--justify_between.cert-controls
          +b.g-cell.g-cols.--8-sm
            +b.H2.de-text--size_md.--color_main.--weight_medium.--height_xs {{ object.title }}
          +b.g-cell.g-cols.--auto-sm
            +b.inliner--center.--jst-end.ma--top_xs-till-xxl(
              v-if="object.countAvailable > 0"
            )
              +b.rounded-icon--2xs.--light-2.ma--right_2xs
                +b.de-icon--type_check.--color_accent-2.--size_custom-xs
              +b.de-text--size_2xs.--weight_light.P {{ _("Есть в наличии") }}
        +b.product-order
          +b.g-row--align_end.--appearance_spaced
            +b.g-cell.g-cols.--12-xs.--4-lg
              +b.g-row--align_end.--appearance_spaced
                +b.g-cell.g-cols.--12-lg.--narrow-xxl(
                  v-if="object.pricePromotion > 0"
                )
                  +b.de-text--size_2md.--size_3sm-lg.--size_2md-xxl.--color_secondary.--variant_line-through.--weight_medium.P {{ object.price }}
                    |&nbsp;
                    span {{_("грн")}}
                +b.g-cell.g-cols.--12-lg.--narrow-xxl(
                  v-if="object.pricePromotion > 0"
                )
                  +b.de-text--size_4md.--size_3md-custom-lg.--size_4md-xxl.--color_accent-2.--weight_medium.P {{ object.pricePromotion }}
                    |&nbsp;
                    +b.SPAN.de-text--size_2md.--size_3sm-lg.--size_2md-xxl.--color_accent-2.--weight_medium {{_("грн")}}
                +b.g-cell.g-cols.--12-lg.--narrow-xxl(
                  v-else
                )
                  +b.de-text--size_4md.--size_3md-custom-lg.--size_4md-xxl.--color_accent-2.--weight_medium.P {{ object.price }}
                    |&nbsp;
                    +b.SPAN.de-text--size_2md.--size_3sm-lg.--size_2md-xxl.--color_accent-2.--weight_medium {{_("грн")}}
            +b.g-cell.g-cols.--12-xs.--6-sm.--4-lg
              +e.additional(v-if="object.countAvailable || object.preorder")
                ui-counter.counter--variant_1(
                  :counter-value="1"
                  :is-disabled="isDisabled"
                  :limit="limit"
                  @input-value="amount = $event"
                  @count="amount + $event"
                  @is-counter-error="isCounterError = $event"
                )
            +b.g-cell.g-cols.--12-xs.--6-sm.--4-lg
              +e.main.--actions(v-if="object.countAvailable || object.preorder")
                bem-button(
                  appearance="fill-accent-2"
                  :space=["sm-icon", "md-icon"]
                  rounded="sm"
                  width="full"
                  :disable="isCounterError === true"
                  @event="validateBeforeSubmit()"
                )
                  row(justify="center" align="center" appearance="nowrap")
                    cell.ma--right_2xs
                      bem-text(
                        tag="p"
                        color="white"
                        size="2xs"
                        weight="bold"
                      ) {{ object.preorder ? _("Предзаказ") : _('Купить') }}
                    cell
                      +b.inliner--center
                        bem-icon(
                          :type="object.preorder ? 'box' : 'cart'"
                          size="sm"
                          color="white"
                        )
      +b.certificate-info
        +b.certificate-page-title--pc
          +e.title
            +e.H1.title-content.de-text.--color_main.--weight_medium.--height_xs {{ _("Електронний подарунковий сертифікат") }}
        validation-observer(
          tag="form"
          ref="observer"
          v-slot="{ errors }"
          @submit.prevent="validateBeforeSubmit"
        )
          +b.cert-form-part
            +e.P.title {{ _("Оберіть дизайн") }}
            +e.DIV.templates-wrapper.g-row--appearance_spaced.--justify_between
              template(
                v-for='template in templates'
              )
                +b.g-cell.g-cols
                  +b.form
                    +b.template-button(
                      @click.prevent="selectTemplate(template)"
                      :class="{ 'is-active': formdata.template === template }"
                    )
                      +e.image.IMG(
                        :alt="template.imageAlt"
                        :title="template.title"
                        :srcset="template.image"
                        :src="template.image"
                      )
          +b.cert-form-part
            +e.P.title {{ _("Ваша інформація") }}
            +b.g-row--appearance_spaced.--space_sm
              +b.g-cell.g-cols.--12-xs.--6-md
                +b.form
                  control-input(
                    rules="custom_email|required"
                    type="email"
                    label-class="form__label"
                    class-name="form__input"
                    :input-label="_('E-mail')"
                    v-model="formdata.email"
                    :required="true"
                    name="email"
                  )
              +b.g-cell.g-cols.--12-xs.--6-md
                +b.form
                  control-input(
                    rules="required"
                    type="text"
                    label-class="form__label"
                    class-name="form__input"
                    :input-label='_("ФІО")'
                    v-model="formdata.name"
                    :required="true"
                    name="name"
                  )
              +b.g-cell.g-cols.--12-xs
                +b.form
                  control-textarea(
                    type="textarea"
                    label-class="form__label"
                    class-name="form__textarea form__textarea--md"
                    :input-label="_('Побажання')"
                    v-model="formdata.description"
                    :required="false"
                    name="description"
                    rules="max:500|min:3"
                  )
                  +b.P.form-add-info {{ _("Максимальна кількість символів: 500") }}
          +b.cert-form-part
            +b.g-row--appearance_spaced.--space_sm
              +b.g-cell.g-cols.--12-xs.--5-md
                +b.P.cert-form-part__title {{ _("Коли відправляємо") }}
                +b.form.ma--bottom_sm
                  control-date-picker-common.datepicker.datepicker--variant_1(
                    class-name="form__input form__input--light-3"
                    label-class="form__label form__label--accent-1"
                    :placeholder="_('Дата')"
                    v-model="formdata.date"
                    :value="formdata.date"
                    @update-date="updateDate"
                    :range="false"
                    value-type="DD.MM.YYYY"
                    format="DD.MM.YYYY"
                    name="date"
                    rules="required"
                    :input-label='_("Дата відправки на e-mail одержувача")'
                  )
              +b.g-cell.g-cols.--12-xs.--7-md
                +b.cert-form-add-info {{ _("Голова йде обертом від святкової метушні? Бігати за подарунками вже немає сил? У нас є для вас швидкий і безпрограшний варіант — подарункові сертифікати в електронному форматі. Кілька кліків—і ми доставимо подарунок в будь-яку точку світу за лічені секунди!")}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { certificateDetailResource } from '@services/catalog.service.js'
import { cartChangeResource } from '@services/order.service.js'
// import { cartData } from '@mixins/order.mixin.js'
import { mapActions } from 'vuex'
import { CART_COUNT_REQUEST } from '@store/action-types'

export default {
  name: 'buy-form-certificate',
  components: {
    'ui-counter': () => import('@app/UiElements/UiCounter'),
  },
  mixins: [FormMixin],
  props: {
    productId: Number,
    objectItem: Object,
  },
  data() {
    return {
      amount: 1,
      id: this.productId,
      isCounterError: false,
      object: {},
      templates: [],
      // selectedTemplate: '',
      formdata: {
        typeProduct: 'certificate',
        template: '',
        name: '',
        email: '',
        description: '',
        date: '',
      },
      custErrors: false,
    }
  },

  computed: {
    isDisabled() {
      return false
    },

    limit() {
      return this.object.preorder ? 999 : this.object.countAvailable
    },
  },
  async mounted() {
    console.log('BuyFormCertificate mounted')
    console.log(this.productId)
    await this.getCertificateDetails()
  },
  methods: {
    async getCertificateDetails() {
      await certificateDetailResource.execute({
        pk: this.productId,
      })
        .then(res => {
          console.log(res)
          this.object = res.data.item
          this.templates = this.object.templates
          // this.selectedTemplate = this.templates[0]
          const [t] = this.template
          this.formdata.template = t
        })
    },
    selectTemplate(template) {
      console.log('selectTemplate', template)
      this.formdata.template = template
    },
    send() {
      this.fieldErrors = []
      // if (!Array.isArray(this.id)) this.id = [this.id]
      return cartChangeResource.execute(
        {},
        {
          entities: [
            {
              element: {
                id: this.id,
                type: 'products.product',
              },
              quantity: this.amount,
              typeProduct: this.formdata.typeProduct,
              // template: this.formdata.template.template,
              certTemplate: this.formdata.template.id,
              name: this.formdata.name,
              email: this.formdata.email,
              description: this.formdata.description,
              date: this.formdata.date,
            },
          ],
        }
      ).then(res => {
        const { data: { item: { products } } } = res
        const addedProduct = products.find(el => el.id === this.productId)
        this.addDatalayerEvent([addedProduct])
        this.cartCountRequest('cart')
        this.custErrors = false
        // open cart modal after product adding
        this.openModal()
      }).catch(async e => {
        this.custErrors = await this.getCommonParsedErrors(e)
        this.updateValidator(this.custErrors)
        this.$emit('updateSkillsErrors', {
          errors: true,
        })
      }).finally(() => {
        this.isLoad = false
      })
      // return authResource.execute({}, data)
      //   .then(() => {
      //     window.location.replace(this.objectItem.redirectLink)
      //   })
    },
    openModal() {
      this.$modal.show(() => import('@app/Modals/CommonModal'), {
        component: 'cart-modal',
        objectItem: this.objectItem,
      },
      {
        name: 'modal',
        classes: 'modal modal--size_custom-1 modal--container modal--padding_sm',
      })
    },
    ...mapActions('common', {
      cartCountRequest: CART_COUNT_REQUEST,
    }),
    updateDate(data) {
      console.log('updateDate')
      this.formdata.date = data
      console.log(this.formdata.date)
    },
    addDatalayerEvent(products) {
      const preparedProducts = products.map(el => {
        el = {
          name: el.title,
          id: el.isbn,
          price: el.price,
          quantity: el.amount,
          category: el.categoryTitle,
        }
        return el
      })
      // eslint-disable-next-line
      dataLayer.push({
        ecommerce: {
          currencyCode: 'UAH',
          add: {
            products: preparedProducts,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
      })
      this.addDatalayerCheckoutEvent(preparedProducts)
    },
    addDatalayerCheckoutEvent(products) {
      // eslint-disable-next-line
      dataLayer.push({
        ecommerce: {
          currencyCode: 'UAH',
          checkout: {
            actionField: { step: 1 },
            products,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Checkout Step 1',
        'gtm-ee-event-non-interaction': 'False',
      })
    },
    getCommonParsedErrors(e) {
      console.log('getCommonParsedErrors')
      return e.json().then(body => {
        const errors = {}
        body.errors.forEach(error => {
          console.log(error)
          if ('request' === error.domain && error.state) {
            if (error.state.entities) {
              error.state.entities.forEach(skillError => {
                Object.assign(errors, skillError)
              })
            }
            Object.assign(errors, error.state)
          }
        })
        return errors
      })
    },
    defaultValidatorErrorsParser(errors) {
      // console.log('defaultValidatorErrorsParser')
      // console.log(errors)
      const messageGetter = x => x.message
      return Object.keys(errors).reduce((acc, x) => {
        if (Array.isArray(errors[x])) {
          acc[x] = errors[x].map(messageGetter)
        } else {
          acc[x] = [errors[x].message]
        }
        return acc
      }, {})
    },
    updateValidator(errors) {
      // console.log('updateValidator')
      const parsed = this.defaultValidatorErrorsParser(errors)
      // console.log(parsed)
      if ('nonFieldErrors' in parsed) {
        this.serverError = parsed.nonFieldErrors
      } else {
        this.serverError = parsed.message
      }
      this.$refs.observer.setErrors(parsed)
      this.isLoading = false
    },
  },
}
</script>
